/*

  File: refs.js
  Kind: Page
  Description: List og general references - may beed pagination if length gets too long

*/

import * as React from 'react';
import { graphql } from 'gatsby'

//Components
import Layout from '../components/layout.js';
import { MDXRenderer } from 'gatsby-plugin-mdx';

//util
import GetFragment from '../components/content/getFragment.js';

// markup
const Refs = ({ data, location }) => {
  const { refs, fragments } = data;
  const Refs = refs.nodes;
  const Fragments = fragments.nodes;

  const RefsTable = Refs.map(({ id, authors, isbn, publisher, title, year, comments }) => {
    return (
      <React.Fragment className="font-sans">
        <div className="col-span-2 md:col-span-3 pl-4">{title}</div>
        <div className="col-span-2 md:col-span-2 pl-4">{authors}</div>
        <div className="col-span-2 md:col-span-2 pl-4">{publisher}</div>
        <div className="col-span-2 md:col-span-1 pl-4">{year}</div>
        <div className="col-span-4 md:col-span-8 text-sm border-b border-neptune-200 mb-4 pb-4  pl-4">
          <span className="font-serif font-bold text-neptune-200">Comments: </span>
          {comments}
        </div>
      </React.Fragment>
    );
  });

  return (
    <Layout pageTitle="references" location={location}>
      <MDXRenderer>{GetFragment(Fragments, 'intro')}</MDXRenderer>
      <div className="grid grid-cols-4 md:grid-cols-8 gap-4 py-4 text-neptune bg-jupiter-900 font-serif font-medium">
        <div className="col-span-2 md:col-span-3 pl-4">Title</div>
        <div className="col-span-2 md:col-span-2 pl-4">Author(s)</div>
        <div className="col-span-2 md:col-span-2 pl-4">Publisher</div>
        <div className="col-span-2 md:col-span-1 pl-4">Year</div>
      </div>
      <div className="grid grid-cols-4 md:grid-cols-8 gap-4 mb-24 py-2">{RefsTable}</div>
    </Layout>
  );
};

export default Refs;

export const query = graphql`
  {
    refs: allRefsJson(sort: { order: ASC, fields: sort }) {
      nodes {
        id
        authors
        isbn
        publisher
        title
        year
        comments
      }
    }
    fragments: allMdx(filter: { fields: { collection: { eq: "fragment" }, locator: { page: { eq: "refs" } } } }) {
      nodes {
        body
        fields {
          locator {
            fragment
          }
        }
      }
    }
  }
`;
